<template>
  <div class="row">
    <div class="col-md-12">
      <b-card class="trazo-no-overflow" header-tag="header" footer-tag="footer">
        <b-card-text>
          <b-row
            class="justify-content-center"
            v-show="showFormSelectEnterprise"
          >
            <form-select-enterprise
              :show="showFormSelectEnterprise"
              v-on:selectEnterprise="setEnterprise"
            />
            <span class="pt-3">
              <b-spinner v-if="loadingData" label="Spinning"></b-spinner>
            </span>
          </b-row>
          <div v-if="!showFormSelectEnterprise">
            <b-button class="mb-2" @click="goBackToSelectEnterprise"
            >Volver
            </b-button
            >
            <trazo-form-label-slot
              labelPosition="top"
              :required="true"
              label="Seleccione un periodo"
            >
              <a-multiselect
                name="period"
                track-by="id"
                label="description"
                v-model="period"
                placeholder="Seleccione un periodo"
                :options="periods"
                :allow-empty="false"
                @select="onSelectPeriod"
              />
            </trazo-form-label-slot>
            <tab-employees-to-assign
              :enterprise="enterprise"
              :employees="employees"
              :payrolls="payrolls"
              :period="period"
              v-on:onUploadPayrollsFile="handlerUploadPayrollFiles"
              abbrev="MEMO"
            />
          </div>
        </b-card-text>
        <template v-slot:footer>
          <portal-target name="pagination_assing_employee_documents">
          </portal-target>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import "vue-multiselect/dist/vue-multiselect.min.css";
import ApiService from "@/core/services/api.service";

import FormSelectEnterprise from "./payroll/FormSelectEnterprise.vue";
import TabEmployeesToAssign from "./payroll/TabEmployeesToAssign.vue";
import TrazoFormLabelSlot from "../../content/TrazoFormLabelSlot.vue";

export default {
  data() {
    return {
      enterprise: null,
      showFormSelectEnterprise: true,

      modalShow: false,
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        {
          key: "full_name",
          sortable: true,
          label: "Nombre"
        },
        {
          key: "actions",
          sortable: true,
          label: "Acciones"
        }
      ],
      enterprises: [],
      employees: [],
      periods: [],
      loadingPeriod: false,
      period: {},
      payrolls: [],
      loadingData: false
    };
  },
  computed: {
    rows() {
      return this.employees.length;
    }
  },
  methods: {
    onSelectPeriod(period) {
      this.period = period;
      this.getPayrollsByPeriod();
    },
    async setEnterprise(enterprise) {
      this.enterprise = enterprise;
      this.loadingData = true;
      await this.getPeriods(enterprise);
      this.loadingData = false;
      this.showFormSelectEnterprise = false;
    },
    getPeriods(enterprise) {
      return ApiService.get(`/api/period`, `?enterprise=${enterprise.id}`).then(
        async ({ data }) => {
          this.periods = data;
          if (this.periods.length !== 0) {
            if (this.periods.length !== 0) {
              this.period = data[0];
            }
            await this.getEmployees(enterprise);
          }
        }
      );
    },
    goBackToSelectEnterprise() {
      this.showFormSelectEnterprise = true;
    },
    // Calls to api
    getEmployees(enterprise) {
      return ApiService.get(
        `/api/enterprise/${enterprise.id}/employees/role`,
        "?role=TRABAJADOR"
      ).then(({ data }) => {
        this.employees = data;
        return this.getPayrollsByPeriod();
      });
    },
    getPayrollsByPeriod() {
      return ApiService.get(`/api/period/${this.period.id}/payrolls`).then(
        ({ data }) => {
          console.log("Los boletas por periodo son: ", data);
          this.payrolls = data;
        }
      );
    },
    handlerUploadPayrollFiles() {
      this.getPayrollsByPeriod();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Gestión de documentos" },
      { title: "Memorandum" }
    ]);
  },
  components: {
    FormSelectEnterprise,
    TabEmployeesToAssign,
    TrazoFormLabelSlot
  }
};
</script>
