<template>
  <fragment>
    <div class="payrollTableHeader">
      <h6 class="mb-5" v-if="!isVisibleAssignEmployeeDocuments">
        Lista de empleados de {{ enterprise.name }}
      </h6>
      <h6 class="mb-5" v-if="isVisibleAssignEmployeeDocuments">
        Seleccione los archivos de planilla
      </h6>
      <div>
        <b-button
          v-if="!isVisibleAssignEmployeeDocuments"
          @click="isVisibleAssignEmployeeDocuments = true"
          class="mr-2"
        >
          <b-icon icon="plus" aria-label="Help"></b-icon>
          Asignar boletas
        </b-button>
        <b-button
          v-if="isVisibleAssignEmployeeDocuments"
          @click="cancelUpload"
          class="mr-2"
          :disabled="sendingFiles"
        >
          <!-- todo function -->
          Cancelar
        </b-button>
        <b-button
          @click="uploadFiles"
          class="mr-2"
          variant="primary"
          :disabled="sendingFiles || files.length === 0"
          v-if="isVisibleAssignEmployeeDocuments"
        >
          <!-- todo function -->
          <b-icon icon="upload" aria-label="Help"></b-icon>
          Subir archivos
        </b-button>
        <span class="pt-3">
          <b-spinner
            v-if="sendingFiles"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </span>
      </div>
    </div>
    <div v-if="isVisibleAssignEmployeeDocuments">
      <trazo-form-label-slot
        labelPosition="top"
        :required="true"
        label="Seleccione un tipo de documento"
        v-if="0 === 1"
      >
        <a-multiselect
          name="document"
          track-by="id"
          label="description"
          v-model="document"
          placeholder="Seleccione un documento"
          :options="documents"
          :allow-empty="false"
          @select="onSelectDocument"
        />
      </trazo-form-label-slot>
      <table class="table table-hover">
        <thead>
        <tr>
          <th>#</th>
          <th>Nombre</th>
          <th>Acciones</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(file, index) in files" :key="file.id">
          <td>{{ index }}</td>
          <td>
            <div class="filename" v-if="file">
              {{ file.name }}
            </div>
          </td>
          <td>
            <div class="h5 mb-0">
              <b-button
                size="sm"
                variant="light"
                @click.prevent="$refs.upload.remove(file)"
              >
                <b-icon icon="trash-fill"></b-icon>
              </b-button>
              <b-button
                v-if="thereIsErrorInFiles === true"
                size="sm"
                variant="danger"
                :id="'a' + file.name"
                title="jaaaaaa"
              >
                <b-icon icon="exclamation-triangle-fill"></b-icon>
              </b-button>
              <b-tooltip
                :target="'a' + file.name"
                triggers="hover"
                :title="getTooltipError(file.name)"
                v-if="thereIsErrorInFiles === true"
              >
              </b-tooltip>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <file-upload
      class="btn btn-primary"
      v-show="isVisibleAssignEmployeeDocuments"
      :disabled="sendingFiles"
      :extensions="extensions"
      :accept="accept"
      :multiple="true"
      :directory="directory"
      :drop="true"
      :dropDirectory="true"
      v-model="files"
      @input-filter="inputFilter"
      @input-file="inputFile"
      ref="upload"
    >
      <i class="fa fa-plus"></i>
      Seleccionar Archivos
    </file-upload>

    <b-modal
      id="modal-select-single-file"
      title="Seleccione un documento"
      @ok="uploadSingleFile"
    >
      <trazo-form-label-slot
        labelPosition="top"
        :required="true"
        label="Seleccione un tipo de documento"
        v-if="1 === 0"
      >
        <a-multiselect
          name="document"
          track-by="id"
          label="description"
          v-model="document"
          placeholder="Seleccione un documento"
          :options="documents"
          :allow-empty="false"
          @select="onSelectDocument"
        />
      </trazo-form-label-slot>

      <p class="my-4">Seleccione un archivo</p>
      <file-upload
        name="file-one"
        class="btn btn-primary"
        :disabled="sendingFiles"
        :extensions="extensions"
        :accept="accept"
        :multiple="false"
        @input-file="setSingleFile"
        ref="upload-one"
      >
        <i class="fa fa-plus"></i>
        Seleccionar
      </file-upload>
      <p v-if="singleFile">{{ singleFile.name }}</p>
    </b-modal>
    <b-table
      v-if="!isVisibleAssignEmployeeDocuments"
      head-variant="dark"
      :items="employees"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(full_name)="data">
        {{ data.item.full_name }}- {{ data.item.id }}
      </template>
      <template #cell(actions)="data">
        <div class="h5 mb-0">
          <label
            v-if="!employeeHasPayroll(data.item.id)"
            size="sm"
            v-b-modal.modal-select-single-file
            variant="light"
            class="btn btn-light"
            @click="currentEmployee = data.item.id"
          >
            <b-icon icon="upload"></b-icon>
          </label>
        </div>
        <div class="h5 mb-0">
          <label
            v-if="employeeHasPayroll(data.item.id)"
            size="sm"
            for="file-one"
            variant="light"
            class="btn btn-light mr-2"
            @click="currentEmployee = data.item.id"
          >
            <b-icon icon="pencil"></b-icon>
          </label>
          <label
            v-if="employeeHasPayroll(data.item.id)"
            size="sm"
            variant="light"
            class="btn btn-light"
            @click="removePayroll(data.item.id)"
          >
            <b-icon icon="trash"></b-icon>
          </label>
        </div>
      </template>
      <template #cell(original)="data">
        <div class="h5 mb-0">
          <label
            v-if="employeeHasPayroll(data.item.id)"
            size="sm"
            variant="light"
            class="btn btn-light"
            @click="donwloadPayroll(data.item.id, 'original')"
          >
            <b-icon icon="eye"></b-icon>
          </label>
          <label
            v-if="
              employeeHasPayroll(data.item.id) &&
                !employeeHasPayrollAdminSigned(data.item.id)
            "
            size="sm"
            variant="light"
            class="btn btn-light ml-2"
            @click="signPayroll(data.item.id)"
          >
            <b-icon icon="vector-pen"></b-icon>
          </label>
        </div>
      </template>
      <template #cell(admin_signed)="data">
        <div class="h5 mb-0">
          <label
            v-if="employeeHasPayrollAdminSigned(data.item.id)"
            size="sm"
            variant="light"
            class="btn btn-light"
            @click="donwloadPayroll(data.item.id, 'admin_signed')"
          >
            <b-icon icon="eye"></b-icon>
          </label>
        </div>
      </template>
      <template #cell(employee_signed)="data">
        <div class="h5 mb-0">
          <label
            v-if="employeeHasPayrollEmployeeSigned(data.item.id)"
            @click="donwloadPayroll(data.item.id, 'employee_signed')"
            size="sm"
            variant="light"
            class="btn btn-light"
          >
            <b-icon icon="eye"></b-icon>
          </label>
        </div>
      </template>
    </b-table>
    <div class="text-center" v-if="employees.length === 0">
      No existen empleados en esta empresa
    </div>
    <portal to="pagination_assing_employee_documents">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </portal>
  </fragment>
</template>

<style lang="scss" scoped>
.payrollTableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
</style>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import ImageCompressor from "@xkeshi/image-compressor";
import TrazoFormLabelSlot from "../../../content/TrazoFormLabelSlot.vue";

import ApiService from "@/core/services/api.service";

export default {
  props: ["enterprise", "employees", "payrolls", "period", "abbrev"],
  data() {
    return {
      thereIsErrorInFiles: false,
      currentEmployee: {},
      showFormSelectEnterprise: true,
      sendingFiles: false,
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        {
          key: "full_name",
          sortable: true,
          label: "Nombre"
        },
        {
          key: "actions",
          sortable: true,
          label: "Acciones"
        },
        {
          key: "original",
          sortable: true,
          label: "Original"
        },
        {
          key: "admin_signed",
          sortable: true,
          label: "Administrador"
        },
        {
          key: "employee_signed",
          sortable: true,
          label: "Empleado"
        }
      ],
      enterprises: [],
      loadingEmployees: false,
      isTouchedForm: false,
      enterprise_hidden: "",
      isTableBaseEmployeeVisible: false,
      fv: null,

      isVisibleAssignEmployeeDocuments: false,
      files: [],
      file: {},
      accept: ".pdf",
      extensions: ["pdf"],
      minSize: 1024,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      thread: 3,
      name: "file",
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      loadingPeriod: false,
      documents: [],
      document: {},
      singleFile: null,
      tooltipErrors: []
    };
  },
  computed: {
    rows() {
      return this.employees.length;
    }
  },
  methods: {
    getTooltipError(fileName) {
      if (fileName in this.tooltipErrors) {
        return this.tooltipErrors[fileName].error;
      } else {
        return "";
      }
    },
    onSelectDocument(document) {
      this.document = document;
    },
    getDocuments() {
      ApiService.get(`/api/document/search/abbrev`, `?abbrev=${this.abbrev}`).then(
        ({ data }) => {
          this.documents.push(data);
          this.document = data.id;
        }
      );
    },
    employeeHasPayroll(employee_id) {
      return (
        this.custom_lodash.findIndex(this.payrolls, {
          employee: employee_id
        }) !== -1
      );
    },
    async removePayroll(employee_id) {
      const payroll = this.custom_lodash.find(this.payrolls, {
        employee: employee_id
      });
      try {
        await ApiService.delete(`/api/payroll/${payroll.id}`);
        this.$emit("onUploadPayrollsFile");
        this.$bvToast.toast("Archivo eliminado satisfactoriamente.", {
          title: `Éxito`,
          variant: "succes",
          solid: true
        });
      } catch (error) {
        this.$bvToast.toast(
          "Ha ocurrido un error, no se pudo eliminar el archivo.",
          {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        );
      }
    },
    employeeHasPayrollAdminSigned(employee_id) {
      const payroll = this.custom_lodash.find(this.payrolls, {
        employee: employee_id
      });
      return payroll?.admin_signed;
    },
    donwloadPayroll(employee_id, type) {
      const payroll = this.custom_lodash.find(this.payrolls, {
        employee: employee_id
      });
      this.downloadFile(payroll[type]);
    },
    async signPayroll(employee_id) {
      const payroll = this.custom_lodash.find(this.payrolls, {
        employee: employee_id
      });
      if (payroll) {
        try {
          await ApiService.get(
            `/api/payroll/${payroll.id}/sign/admin`,
            `?enterprise=${this.enterprise.id}`
          );
          this.$emit("onUploadPayrollsFile");
          this.$bvToast.toast("Boleta firmada correctamente.", {
            title: `Éxito`,
            variant: "succes",
            solid: true
          });
        } catch (error) {
          this.$bvToast.toast(
            "Ha ocurrido un error, no se pudo eliminar el archivo.",
            {
              title: `Error`,
              variant: "danger",
              solid: true
            }
          );
        }
      } else {
        this.$bvToast.toast("La boleta no existe, intente con otra boleta.", {
          title: `Error`,
          variant: "danger",
          solid: true
        });
      }
    },
    employeeHasPayrollEmployeeSigned(employee_id) {
      const payroll = this.custom_lodash.find(this.payrolls, {
        employee: employee_id
      });
      return payroll?.employee_signed;
    },
    downloadFile(url) {
      window.open(url, "_blank");
    },
    setEnterprise(enterprise) {
      this.enterprise = enterprise;
    },
    uploadFiles() {
      if (this.files.length === 0) {
        return;
      }
      this.sendingFiles = true;
      const formData = new FormData();
      this.files.map(el => {
        formData.append("files[]", el.file, el.file.name);
      });
      formData.append("period", this.period.id);
      formData.append("document", this.document);
      ApiService.post(`/api/payroll/upload-bulk/`, formData, {
        headers: {
          "Content-Type": " multipart/form-data"
        }
      })
        .then(({ data: { files } }) => {
          this.sendingFiles = false;

          files.forEach(el => {
            if (el.status !== "fail") {
              console.log("before this.files", this.files);
              this.files = this.files.filter(file => file.name !== el.name);
              console.log("after this.files", this.files);
            }
          });
          console.log("this.files", this.files);

          if (this.files.length === 0) {
            this.thereIsErrorInFiles = false;
            this.$bvToast.toast("Archivos agregado satisfactoriamente", {
              title: `Éxito`,
              variant: "succes",
              solid: true
            });
            this.isVisibleAssignEmployeeDocuments = false;
            this.files = [];
            this.$emit("onUploadPayrollsFile");
          } else {
            this.thereIsErrorInFiles = true;

            files.forEach(el => {
              this.tooltipErrors[el.name] = {};
              this.tooltipErrors[el.name].error = el.message;
            });

            this.$bvToast.toast("Ha ocurrido un error, revise los archivos.", {
              title: `Error`,
              variant: "danger",
              solid: true
            });
          }
        })
        .catch(error => {
          console.log("error", error);
          this.sendingFiles = false;
        });
    },
    cancelUpload() {
      this.$emit("onUploadPayrollsFile");
      this.isTableBaseEmployeeVisible = true;
      this.isVisibleAssignEmployeeDocuments = false;
      this.files = [];
    },
    setSingleFile(file) {
      this.singleFile = file;
    },
    uploadSingleFile() {
      const payroll = this.custom_lodash.find(this.payrolls, {
        employee: this.currentEmployee
      });
      const formData = new FormData();
      formData.append("original", this.singleFile.file, this.singleFile.name);
      formData.append("document", this.document);
      formData.append("period", this.period.id);
      formData.append("employee", this.currentEmployee);
      formData.append("is_active", true);
      const functionSave = payroll ? ApiService.patch : ApiService.post;

      functionSave(
        `/api/payroll/${payroll ? payroll.id + "/" : ""}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
        .then(() => {
          this.singleFile = null;
          this.$bvToast.toast("Archivo agregado satisfactoriamente", {
            title: `Éxito`,
            variant: "succes",
            solid: true
          });
          this.$emit("onUploadPayrollsFile");
        })
        .catch(() => {
          this.$bvToast.toast(
            "Ha ocurrido un error, revise si es el archivo correcto.",
            {
              title: `Error`,
              variant: "danger",
              solid: true
            }
          );
        });
    },
    inputFilter(newFile, oldFile, prevent) {
      console.log("EVENTO INPUT FILTER");
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Automatic compression
        // 自动压缩
        if (
          newFile.file &&
          newFile.type.substr(0, 6) === "image/" &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = "compressing";
          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 512,
            maxHeight: 512
          });
          imageCompressor
            .compress(newFile.file)
            .then(file => {
              this.$refs.upload.update(newFile, {
                error: "",
                file,
                size: file.size,
                type: file.type
              });
            })
            .catch(err => {
              this.$refs.upload.update(newFile, {
                error: err.message || "compress"
              });
            });
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        // 创建 blob 字段
        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        // 缩略图
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }
    }, // add, update, remove File Event
    inputFile(newFile) {
      console.log("EVENTO INPUT FILE");
      console.log("newFile", newFile);
      if (newFile !== undefined) {
        this.thereIsErrorInFiles = false;
      }
    }
  },
  mounted() {
    this.getDocuments();
  },
  components: {
    TrazoFormLabelSlot
  }
};
</script>
